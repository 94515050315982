<template>
  <div class="page" :class="{ bg2: IsChangeLoginPage }">
    <!-- <div class="logo-top">
      <img src="../assets/img/login/logo03.png">
    </div> -->
    <div class="center-div flex-center">
      <div class="center-box flex-center">
        <div class="left-box flex-center-column">
          <!-- <img src="../assets/img/login/dongtu.gif"> -->
        </div>
        <div class="right-box flex-center-column">
          <div class="login-form">
            <div class="t1">欢迎登录</div>
            <div class="t2">{{ IsChangeLoginPage ? '全国煎药中心管理后台' : '机构管理后台' }}</div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="login-ruleForm">
              <div class="flex-center-column">
                <el-input placeholder="请输入您的账号" prefix-icon="el-icon-user" v-model="ruleForm.username"> </el-input>
                <el-input type="password" prefix-icon="el-icon-lock" v-model="ruleForm.password" placeholder="请输入您的密码"> </el-input>
                <el-button type="primary" @click="login()">登录</el-button>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notice } from '../components/Notice/Notice.js'
import { User } from '../components/HospitalDomain/User.js'
import { Role } from '../components/HospitalDomain/Role'
export default {
  name: 'login',
  data() {
    var role = new Role(this.TokenClient, this.Services.Authorization)
    var notice = new Notice(this.TokenClient, this.Services.Notice)
    return {
      Notice: notice,
      RoleDomain: role,
      checked: false,
      ruleForm: {
        username: '',
        password: '',
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' },
        ],
      },
      noticeInfo: {
        isEnable: false,
      },
    }
  },
  mounted() {
    this.getNotice()
  },
  methods: {
    getNotice() {
      var _this = this
      _this.Notice.GetNotice(
        function (data) {
          _this.noticeInfo = data.data
        },
        function (error) {
          console.log(error)
        }
      )
    },
    //登录
    login() {
      var _this = this
      let { username, password } = this.ruleForm
      let userInfo = new User(this.TokenClient, this.Services.Authorization)
      if (userInfo.Login(username.replace(/(^\s*)|(\s*$)/g, ''), password.replace(/(^\s*)|(\s*$)/g, ''))) {
        _this.RoleDomain.AllManagerMenus(
          function (data) {
            let keys = Object.keys(data.data)[0]
            if (keys && data.data[keys].length > 0) {
              sessionStorage.setItem('AllMenus', JSON.stringify(data.data[keys]))
              _this.initWebSocket()
              if (_this.noticeInfo.isEnable) {
                _this.$alert(_this.noticeInfo.noticeMessage, '通知', {
                  confirmButtonText: '我知道了',
                  showClose: false,
                  callback: (action) => {
                    if (_this.ClientId == _this.YunClient || _this.ClientId == _this.TotalClient) {
                      _this.$router.push('/OutpatientIndex')
                    } else {
                      _this.$router.push('/index')
                    }
                  },
                })
              } else {
                if (_this.ClientId == _this.YunClient || _this.ClientId == _this.TotalClient) {
                  _this.$router.push('/OutpatientIndex')
                } else {
                  _this.$router.push('/index')
                }
              }
            } else {
              this.$message.error('请联系管理员！')
            }
          },
          function (err) {
            // sessionStorage.setItem('AllMenus', JSON.stringify(err.data.机构后台))
            // _this.$router.push('/index');
            let keys = Object.keys(err.data)[0]
            if (keys && err.data[keys].length > 0) {
              sessionStorage.setItem('AllMenus', JSON.stringify(err.data[keys]))
              if (!_this.$socket.connected) {
                _this.initWebSocket()
              }
              if (_this.noticeInfo.isEnable) {
                _this.$alert(_this.noticeInfo.noticeMessage, '通知', {
                  confirmButtonText: '我知道了',
                  showClose: false,
                  callback: (action) => {
                    if (_this.ClientId == _this.YunClient || _this.ClientId == _this.TotalClient) {
                      _this.$router.push('/OutpatientIndex')
                    } else {
                      _this.$router.push('/index')
                    }
                  },
                })
              } else {
                if (_this.ClientId == _this.YunClient || _this.ClientId == _this.TotalClient) {
                  _this.$router.push('/OutpatientIndex')
                } else {
                  _this.$router.push('/index')
                }
              }
            } else {
              this.$message.error('请联系管理员！')
            }
          }
        )
      } else {
        this.$message.error('账号密码错误，请重新输入！')
      }
    },
    async initWebSocket() {
      let token = JSON.parse(sessionStorage.getItem(this.ClientId + 'Token')).access_token
      this.$socket.connect(this.Services.MsgWss, token)
    },
  },
}
</script>

<style lang="scss" scoped>

.page {
  width: 100vw;
  height: 100vh;
  background: url('../assets/img/login/bg001.png');
  background-size: 100% auto;

  .logo-top {
    position: fixed;
    left: 1.5vw;
    top: 1.2vw;
    height: 5vh;

    img {
      height: 6vh;
    }
  }
  .logo-top2 {
    position: fixed;
    left: 1.5vw;
    top: 1.2vw;
    height: 8vh;

    img {
      height: 8vh;
    }
  }

  .center-box {
    width: 65%;
    margin: 20vh auto;
    height: 60vh;
    box-shadow: 0px 12px 20px 0px rgba(133, 192, 205, 0.32);
    border-radius: 24px;

    .left-box {
      width: 60%;
      background: url('https://api.qyyl365.com/authorization/Background/bgLogin.gif') no-repeat;
      background-size: cover;
      background-position: center;
      height: 65vh;
      border-radius: 24px 0 0 24px;

      img {
        height: 50vh;
      }
    }

    .right-box {
      width: 40%;
      height: 65vh;
      background-color: #ffffff;
      border-radius: 0 24px 24px 0;

      .login-form {
        width: 80%;
        margin: 0 auto;

        .t1 {
          font-size: 32px;
          font-weight: 400;
          color: var(--base--Color);
          text-align: center;
        }

        .t2 {
          font-size: 28px;
          font-weight: 400;
          color: #404040;
          line-height: 40px;
          text-align: center;
        }

        ::v-deep .el-input {
          margin-bottom: 2vh;
        }

        .login-ruleForm {
          margin-top: 5vh;

          ::v-deep .el-input__icon {
            color: #333333;
            height: 60px;
            line-height: 60px;
            font-size: 20px;
          }

          ::v-deep .el-input__prefix {
            left: 15px;
            height: 60px;
          }

          ::v-deep .el-input__inner {
            height: 60px;
            padding: 10px 30px 10px 60px;
            padding-left: 50px;
            border: 1px solid #e6e6e6;
            border-radius: 6px;
          }

          .el-button {
            margin-top: 2vh;
            width: 60%;
            height: 60px;
            border-radius: 30px;
            background: var(--base--Color);
            font-size: 18px;
            color: #ffffff;
            border: none;
          }

          .el-checkbox {
            color: #ffffff;

            .el-checkbox__input.is-checked .el-checkbox__inner {
              background-color: #ff9d01;
              border-color: #ff9d01;
            }

            .el-checkbox__input.is-checked + .el-checkbox__label {
              color: #ff9d01;
            }
          }

          a {
            font-size: 14px;
          }

          a:hover {
            color: #ff9d01;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.bg2 {
  background: url('../assets/img/login/bg_jyzx.jpg');
  .left-box {
      background: url('../assets/img/login/yaocai.jpg') no-repeat !important;
      background-size: 100% 100% !important;
    }
}
</style>
